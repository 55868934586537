/* eslint-disable no-console */
const DEBUG = process.env.NODE_ENV !== 'production';
const useSW = process.env.NODE_ENV !== 'development' || process.env.REACT_APP_SW;

if ('serviceWorker' in navigator) {
  if (useSW) {
    navigator.serviceWorker
      .register('./sw.js', { scope: './' })
      .then(reg => {
        if (DEBUG) {
          console.group(`Service worker - Registration succeeded`);
          console.log(reg);
          console.groupEnd();
        }
      })
      .catch(error => {
        if (DEBUG) {
          console.group(`Service worker - Registration failed`);
          console.log(error);
          console.groupEnd();
        }
      });
  } else {
    navigator.serviceWorker.ready.then(registration => {
      if (DEBUG) console.log('Service worker - Unregister');
      registration.unregister();
    });
  }
}
