import React, {useEffect} from "react"

import PropTypes from "prop-types"
import {Route, useHistory} from "react-router-dom"

import urls from "../config/urls";
import localStorageKeys from "../config/localStorageKeys";


const PrivateRoute = ({path, children}) => {
  const history = useHistory();
  useEffect(() => {
    const token = window.localStorage.getItem(localStorageKeys.token)
    if (!token) {
      window.localStorage.removeItem(localStorageKeys.token)
      history.push(urls.LOGIN)
    }
  }, [])
  return <Route path={path}>{children}</Route>
}

PrivateRoute.defaultProps = {}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
}

export default PrivateRoute
