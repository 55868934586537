import React, {useEffect} from "react"

import PropTypes from "prop-types"
import { Route, useHistory } from "react-router-dom"

import urls from "../config/urls";
import localStorageKeys from "../config/localStorageKeys";

const PublicRoute = ({ path, children }) => {
  const history = useHistory();
  useEffect(() => {
    const token = window.localStorage.getItem(localStorageKeys.token)
    if (token) {
      history.push(urls.GENERATOR)
    }
  }, [])
  return <Route path={path}>{children}</Route>
}

PublicRoute.defaultProps = {}

PublicRoute.propTypes = {
  path: PropTypes.string.isRequired,
}

export default PublicRoute
