import React from "react"

import {BrowserRouter as Router, Switch} from "react-router-dom"

import urls from "./config/urls"
import Generator from "./Generator"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import PublicRoute from "./PublicRoute/PublicRoute"

import './serviceWorker';

const App = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute path={urls.LOGIN}>
          <Login/>
        </PublicRoute>
        <PrivateRoute path={urls.GENERATOR}>
          <Generator/>
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

export default App
