import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom";
import urls from "../config/urls";
import {Button, CircularProgress, makeStyles} from "@material-ui/core";
import TicketForm from "./components/TicketForm";
import localStorageKeys from "../config/localStorageKeys";

// import PropTypes from "prop-types"

const useStyles = makeStyles({
  wrapper: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '3rem .5rem',
    display: 'grid',
    gridGap: '2rem',
    justifyItems: 'end'
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '1rem'
  }
})

const getInitDate = () => ( {time: new Date(), day: 'PONIEDZIALEK'} );

const getInitTicket = () => ( {
  name: '',
  surname: '',
  dates: [ getInitDate() ],
  // year: new Date().getFullYear(),
  // season: new Date().getMonth() >= 6 ? 'winter' : 'summer',
} )

const Generator = () => {
  const history = useHistory();
  const token = JSON.parse(window.localStorage.getItem(localStorageKeys.token));
  const [ tickets, setTickets ] = useState([ getInitTicket() ])
  const [ loading, setLoading ] = useState(false);

  const authCheck = async () => {
    if (!token) history.push(urls.LOGIN)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth': token?.data,
        'iv': token?.iv,
      },
    })
    if (response.status >= 300) {
      window.localStorage.removeItem(localStorageKeys.token);
      history.push(urls.LOGIN)
    }
  };

  useEffect(() => {
    window.onfocus = authCheck;
    authCheck()
    return () => window.onfocus = null
  }, [])

  const cleanForm = () => setTickets(prev => [ {...getInitTicket(), dates: prev[ 0 ].dates} ])

  const getImage = async ticket => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth': token.data,
        'iv': token.iv,
      },
      body: JSON.stringify(ticket)
    })
    if (response.status >= 300) {
      window.localStorage.removeItem(localStorageKeys.token);
      history.push(urls.LOGIN)
    }
    const blob = await response.blob();
    const URL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = URL;
    tempLink.setAttribute('download', `${ticket.name}_${ticket.surname}.png`);
    tempLink.click();
  }

  const handleSubmit = async () => {
    setLoading(true)
    await Promise.all(tickets.map(ticket => getImage(ticket)))
    cleanForm()
    setLoading(false)
  }

  const onTicketAdd = () => setTickets(prev => [ ...prev, getInitTicket() ])

  const onTicketRemove = index => () => setTickets(prev => prev.splice(index, 1))

  const onChange = index => data => {
    setTickets(prev => {
      const newTickets = [ ...prev ]
      newTickets[ index ] = {...newTickets[ index ], ...data};
      return newTickets
    })
  }

  const onDateChange = ticketIndex => ({dateIndex, day, time}) => {
    setTickets(prev => {
      const newTickets = [ ...prev ]
      const newDates = [ ...newTickets[ ticketIndex ].dates ]
      newDates[ dateIndex ] = {...newDates[ dateIndex ], ...( day && {day} ), ...( time && {time} )}
      newTickets[ ticketIndex ] = {...newTickets[ ticketIndex ], dates: newDates};
      return newTickets
    })
  }

  const onDateAdd = ticketIndex => () => {
    setTickets(prev => {
      const newTickets = [ ...prev ]
      newTickets[ ticketIndex ].dates.push(getInitDate())
      return newTickets;
    })
  }

  const onDateRemove = ticketIndex => dateIndex => {
    setTickets(prev => {
      const newTickets = [ ...prev ]
      newTickets[ ticketIndex ].dates.splice(dateIndex, 1)
      return newTickets;
    })
  }

  const logout = () => {
    window.localStorage.removeItem(localStorageKeys.token)
    history.push(urls.LOGIN)
  }

  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Button onClick={logout} size="small" color='secondary' variant='contained'>WYLOGUJ</Button>
      {tickets && tickets.map((data, index) => (
        <TicketForm
          onDateAdd={onDateAdd(index)}
          onDateRemove={onDateRemove(index)}
          onChange={onChange(index)}
          onDateChange={onDateChange(index)}
          onTicketRemove={onTicketRemove(index)}
          isFirst={index === 0}
          key={index.toString()}
          {...data}
        />
      ))}
      <div className={styles.buttons}>
        <Button disabled={loading} onClick={onTicketAdd} variant="outlined" color="primary">
          Dodaj karnet
        </Button>
        <Button disabled={loading} onClick={handleSubmit} variant="contained" color="primary">
          {loading ? <CircularProgress size={26}/> : 'Generuj'}
        </Button>
      </div>
    </div>
  )
}

Generator.defaultProps = {}

Generator.propTypes = {}

export default Generator
