import React, {useState} from "react"

import {
  makeStyles,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  FormControl,
  Button,
  TextField, Paper, CircularProgress
} from "@material-ui/core"


import {Visibility, VisibilityOff, AccountCircle} from '@material-ui/icons';
import localStorageKeys from "../config/localStorageKeys";
import {useHistory} from "react-router-dom";
import urls from "../config/urls";

// import PropTypes from "prop-types"

const useStyles = makeStyles({
  main: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: '2rem calc(1rem + 2vw) 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: '2rem',
    fontSize: '4rem',
  },
  form: {
    display: 'grid',
    gridGap: '1rem',
  },
  error: {
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: 'crimson',
    minHeight: '2rem',
    maxWidth: '250px',
    margin: '0 auto',
  }
})

const Login = () => {
  const history = useHistory();
  const [ state, setState ] = useState({
    login: '',
    password: ''
  });
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

  const handleChange = (prop) => ({target}) => {
    setState(prev => ( {...prev, [ prop ]: target.value} ));
  };

  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  const handleSubmit = async event => {
    event.preventDefault();
    setError(false);
    setLoading(true)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(state)
    })
    if(response.ok) {
      const { encryptedData, iv } = await response.json()
      const token = {
        data: encryptedData,
        iv,
      }
      window.localStorage.setItem(localStorageKeys.token, JSON.stringify(token))
      history.push(urls.GENERATOR)
    } else {
      const { error } = await response.json()
      setError(error)
    }
    setLoading(false)
  }

  const styles = useStyles()
  return (
    <main className={styles.main}>
      <Paper elevation={2} className={styles.paper}>
        <AccountCircle className={styles.icon}/>
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormControl variant="outlined">
            <TextField
              name="login"
              onChange={handleChange('login')}
              value={state.login}
              variant="outlined"
              label="Nazwa użytkownika"
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="password">Hasło</InputLabel>
            <OutlinedInput
              name="password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={state.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={45}
            />
          </FormControl>
          <Button
            disabled={!state.login || !state.password || loading}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {loading ? <CircularProgress size={26} /> : 'Zaloguj'}
          </Button>
          <p className={styles.error}>
            {error && error}
          </p>
        </form>
      </Paper>
    </main>
  )
}

Login.defaultProps = {}

Login.propTypes = {}

export default Login
