import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  IconButton,
  Button,
} from "@material-ui/core";

import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {Close} from '@material-ui/icons';

const useStyles = makeStyles({
  paper: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    boxSizing: 'border-box',
  },
  last: {
    display: 'flex'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    minHeight: '2rem',
    paddingBottom: '.5rem',
  }
})

const TicketForm = ({
                      name,
                      surname,
                      dates,
                      year,
                      season,
                      onChange,
                      onDateChange,
                      onDateAdd,
                      onDateRemove,
                      onTicketRemove,
                      isFirst
                    }) => {
  const styles = useStyles();

  const handleChange = ({target}) => {
    onChange({[ target.name ]: target.value})
  }

  const handleDayChange = index => ({target}) => {
    onDateChange({[ target.name ]: target.value, dateIndex: index})
  }

  const handleTimeChange = index => date => {
    onDateChange({time: date, dateIndex: index})
  }

  const commonProps = {
    variant: "outlined",
    onChange: handleChange,
    fullWidth: true,
  }

  const gridSizes = {
    sm: 6, xs: 12,
  }

  return (
    <Paper className={styles.paper} elevation={2}>
      <div className={styles.header}>
        {!isFirst && (
          <IconButton onClick={onTicketRemove} size="small" aria-label="delete" color="primary">
            <Close/>
          </IconButton>
        )}
      </div>
      <Grid spacing={2} container>
        <Grid item {...gridSizes}>
          <TextField {...commonProps} label="Imię" name="name" value={name}/>
        </Grid>
        <Grid item {...gridSizes}>
          <TextField {...commonProps} label="Nazwisko" name="surname" value={surname}/>
        </Grid>
        {/*<Grid item {...gridSizes}>*/}
        {/*  <FormControl {...commonProps}>*/}
        {/*    <InputLabel htmlFor="yearlabel" id="yearlabel">Rok</InputLabel>*/}
        {/*    <Select*/}
        {/*      native={isMobile}*/}
        {/*      labelId="yearlabel"*/}
        {/*      id="year"*/}
        {/*      name="year"*/}
        {/*      value={year}*/}
        {/*      onChange={handleChange}*/}
        {/*      label="Rok"*/}
        {/*    >*/}
        {/*      <OptionComponent value="2021">2021</OptionComponent>*/}
        {/*      <OptionComponent value="2022">2022</OptionComponent>*/}
        {/*      <OptionComponent value="2023">2023</OptionComponent>*/}
        {/*      <OptionComponent value="2024">2024</OptionComponent>*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
        {/*<Grid item {...gridSizes}>*/}
        {/*  <FormControl {...commonProps}>*/}
        {/*    <InputLabel htmlFor="labelSeason" id="labelSeason">Sezon</InputLabel>*/}
        {/*    <Select*/}
        {/*      native={isMobile}*/}
        {/*      labelId="labelSeason"*/}
        {/*      value={season}*/}
        {/*      onChange={handleChange}*/}
        {/*      label="Sezon"*/}
        {/*      name="season"*/}
        {/*    >*/}
        {/*      <OptionComponent value="summer">WIOSNA/LATO</OptionComponent>*/}
        {/*      <OptionComponent value="winter">JESIEŃ/ZIMA</OptionComponent>*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
        {dates.map(({time, day}, index) => {
          const isLast = index === dates.length - 1;
          return (
            <>
              <Grid key={index} item spacing={2} container>
                <Grid item xs={6}>
                  <FormControl {...commonProps}>
                    <InputLabel htmlFor="labelDay" id="labelDay">Dzień</InputLabel>
                    <Select
                      labelId="labelDay"
                      value={day}
                      onChange={handleDayChange(index)}
                      label="Dzień"
                      name="day"
                    >
                      <MenuItem value="PONIEDZIALEK">PONIEDZIAŁEK</MenuItem>
                      <MenuItem value="WTOREK">WTOREK</MenuItem>
                      <MenuItem value="SRODA">ŚRODA</MenuItem>
                      <MenuItem value="CZWARTEK">CZWARTEK</MenuItem>
                      <MenuItem value="PIATEK">PIĄTEK</MenuItem>
                      <MenuItem value="SOBOTA">SOBOTA</MenuItem>
                      <MenuItem value="NIEDZIELA">NIEDZIELA</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={styles.last}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      fullWidth
                      label="Godzina"
                      minutesStep={5}
                      inputVariant="outlined"
                      ampm={false}
                      value={time}
                      autoOk={true}
                      onChange={handleTimeChange(index)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              {isLast && (
                <Grid item container className={styles.buttons}>
                  {dates.length > 1 && (
                    <Button onClick={() => onDateRemove(index)} size="small" aria-label="delete" color="secondary">
                      Usuń
                    </Button>
                  )}
                  <Button onClick={onDateAdd} size="small" aria-label="add" color="primary">
                    Dodaj zajęcia
                  </Button>
                </Grid>
              )}
            </>
          )
        })}
      </Grid>
    </Paper>
  );
}

TicketForm.defaultProps = {};

TicketForm.propTypes = {};

export default TicketForm;